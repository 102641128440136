import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'

const ContactForm = ({formData,setFormData,handleSubmit}) => {
  return (
    <div className='col-span-1' >
        <Slide triggerOnce> <p className='text-[1.1rem]  font-[RocaBold] text-[#FFC108]' >Get in Touch with Us</p></Slide>
        <Slide triggerOnce> <h3 className='font-[LeagueBold]  my-3  leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Fill the Form and Contact Now</h3></Slide>
        <div className='lg:pt-6 pt-3' >
            <input value={formData?.name} onChange={(e)=>setFormData({...formData,name:e.target.value})} className='border-[1px] my-2 font-[LeagueMedium] outline-none border-gray-400 py-3 px-3 rounded-md lg:w-[600px] w-full placeholder:text-gray-600' type='text' placeholder='Enter Your Name' />
            <input value={formData?.email} onChange={(e)=>setFormData({...formData,email:e.target.value})} className='border-[1px] my-2 font-[LeagueMedium] outline-none border-gray-400 py-3 px-3 rounded-md lg:w-[600px] w-full placeholder:text-gray-600' type='email' placeholder='Enter Your Email' />
            <input value={formData?.contact} onChange={(e)=>setFormData({...formData,contact:e.target.value})} className='border-[1px] my-2 font-[LeagueMedium] outline-none border-gray-400 py-3 px-3 rounded-md lg:w-[600px] w-full placeholder:text-gray-600' type='number' placeholder='Enter Your Contact No' />
            <input value={formData?.subject} onChange={(e)=>setFormData({...formData,subject:e.target.value})} className='border-[1px] my-2 font-[LeagueMedium] outline-none border-gray-400 py-3 px-3 rounded-md lg:w-[600px] w-full placeholder:text-gray-600' type='text' placeholder='Enter Your Subject' />
            <textarea value={formData?.message} onChange={(e)=>setFormData({...formData,message:e.target.value})} className='border-[1px] h-[150px] my-2 font-[LeagueMedium] outline-none border-gray-400 py-3 px-3 rounded-md lg:w-[600px] w-full placeholder:text-gray-600' type='text' placeholder='Message' />
            <div className='flex items-center justify-center lg:w-[600px] w-full' >
            <button onClick={handleSubmit} className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Visitor</button>
            </div>
        </div>
    </div>
  )
}

export default ContactForm