import React from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import GalleryList from '../components/Gallery/GalleryList'
import Footer from '../components/Layout/Footer'
import VideoGallery from '../components/Gallery/VideoGallery'

const Gallery = () => {
  return (
    <div>
        <NavbarWithMegaMenu />
        <GalleryList />
        <VideoGallery />
        <Footer />
    </div>
  )
}

export default Gallery