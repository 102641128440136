import React from 'react'
import ImageOne from "../../asset/glimpse/image1.jpg"
import ImageTwo from "../../asset/glimpse/image2.jpg"
import ImageThree from "../../asset/glimpse/image3.jpg"
import ImageFour from "../../asset/glimpse/image4.jpg"
import ImageFive from "../../asset/glimpse/image5.jpg"
import ImageSix from "../../asset/glimpse/image6.jpg"
import ImageSeven from "../../asset/glimpse/image7.jpg"
import ImageEight from "../../asset/glimpse/image8.jpg"
import ImageNine from "../../asset/glimpse/image9.jpg"
import ImageTen from "../../asset/glimpse/image10.jpg"
import ImageEleven from "../../asset/glimpse/image11.jpg"
import ImageTwelve from "../../asset/glimpse/image12.jpg"
import ImageThirteen from "../../asset/glimpse/image13.jpg"
import ImageForteen from "../../asset/glimpse/image14.jpg"
import ImageFifteen from "../../asset/glimpse/image15.jpg"
import ImageSixteen from "../../asset/glimpse/image16.jpg"
import ImageSeventeen from "../../asset/glimpse/image17.jpg"
import ImageEighteen from "../../asset/glimpse/image18.jpg"
import ImageNinteen from "../../asset/glimpse/image19.jpg"
import ImageTwenty from "../../asset/glimpse/image20.jpg"
import ImageTwentyOne from "../../asset/glimpse/image21.jpeg"
import ImageTwentyTwo from "../../asset/glimpse/image22.jpeg"
import ImageTwentyThree from "../../asset/glimpse/image23.png"
import ImageTwentyFour from "../../asset/glimpse/image24.png"
import ImageTwentyFive from "../../asset/glimpse/image25.png"
import ImageTwentySix from "../../asset/glimpse/image26.png"
import ImageTwentySeven from "../../asset/glimpse/image27.png"
import ImageTwentyEight from "../../asset/glimpse/image28.png"
import ImageTwentyNine from "../../asset/glimpse/image29.png"
import ImageThirty from "../../asset/glimpse/image30.png"
import ImageThirtyOne from "../../asset/glimpse/image31.png"
import ImageThirtyTwo from "../../asset/glimpse/image32.png"
import { Link } from 'react-router-dom'
const GalleryList = () => {
  return (
    <div className='lg:px-12 px-4 py-3 lg:py-12' >
         <div className='lg:flex items-center justify-between' >
    <div >
         <p className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Style, Inspiration, and Everything In Between</p>
        <h3 className='font-[LeagueBold] text-center lg:text-left  my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Glimpse of Our Exhibitions</h3>
        </div>
        
        </div>
        <div className='grid py-3 lg:py-8 lg:grid-cols-4 grid-flow-row gap-6 ' >
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageOne} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyOne} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwo} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageThree} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentySeven} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyTwo} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageFour} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageFive} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageSix} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentySix} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyThree} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageSeven} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageEight} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageNine} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyFour} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyEight} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageThirtyTwo} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageEleven} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwelve} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageThirteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageForteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageThirtyOne} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageFifteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageSixteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageSeventeen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyFive} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwentyNine} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageEighteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageNinteen} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageTwenty} alt="" />
            </div>
            <div>
                <img className='rounded-lg h-[300px] shadow-lg' src={ImageThirty} alt="" />
            </div>
        </div>
    </div>
  )
}

export default GalleryList