import React from 'react'
import {Slide, Zoom} from "react-awesome-reveal"
import AboutImageOne from "../../asset/aboutUs2.jpg"
import { Link } from 'react-router-dom'
const OurVision = () => {
  return (
    <div className='grid  items-center px-4 lg:px-12 py-3 lg:py-12 grid-cols-1 lg:grid-cols-5 lg:grid-flow-row grid-flow-reverse-row gap-4 lg:gap-12' >
    <div className='lg:col-span-2 order-last lg:order-first' >
    <Zoom>
        <img className='rounded-lg shadow-lg ' src={AboutImageOne} />
        </Zoom>
    </div>
    <div className='lg:col-span-3' >
    <Slide direction='right' >
        <h5 className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Our Vision</h5>
        <h3 className='font-[LeagueBold] my-3 leading-[3rem] text-[2.5rem]' > Inspiring Lifestyle Evolution</h3>
        <p className='font-[LeagueRegular] text-[0.8rem] lg:text-[1.2rem] text-gray-600' > At Ekttaar, our vision is to transform perspectives and shape the future of lifestyle experiences. We aim to lead the industry by continuously innovating and redefining what it means to engage with fashion, beauty, art, and design. Through our curated exhibitions, we strive to inspire creativity, foster meaningful connections, and elevate the standards of luxury living. Our vision is to be recognized as a global leader in lifestyle exhibitions, setting trends and creating immersive environments that captivate and inspire. <br/><br/>
        We envision a future where everyone can access and appreciate the transformative power of lifestyle, enriching lives and leaving a lasting impact on individuals and communities worldwide. Join us as we pave the way towards a more stylish, connected, and enriching world.
        </p>
        <Link to="/visitor-registration" >
        <button className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Visitor</button>
        </Link>
        </Slide>
    </div>
    
</div>
  )
}

export default OurVision