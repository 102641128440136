import React from 'react'
import ContactBannerImage from "../../asset/contactBanner.png"
const AboutBanner = () => {
  return (
    <div style={{backgroundImage:`url(${ContactBannerImage})`}} className='bg-center flex items-center justify-center h-[400px] bg-cover' >
        <div >
            <h3 className='text-white uppercase font-[LeagueBold] text-[3rem]' >About Ekttaar</h3>
        </div>
    </div>
  )
}

export default AboutBanner