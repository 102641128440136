import React from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import GuideBanner from '../components/VisitorGuide/GuideBanner'
import FounderBanner from '../components/Founder/FounderBanner'
import Quote from '../components/Founder/Quote'
import AboutFounder from '../components/About/AboutFounder'
import Footer from '../components/Layout/Footer'

const Founder = () => {
  return (
    <div>
        <NavbarWithMegaMenu />
        <FounderBanner />
        <Quote />
        <AboutFounder />
        <Footer />
    </div>
  )
}

export default Founder