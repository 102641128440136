import React, { useEffect, useState } from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import GuideBanner from '../components/ExhibitorGuide/GuideBanner'
import Footer from '../components/Layout/Footer'
import ExhibitorManual from '../components/ExhibitorGuide/ExhibitorManual'
import Services from '../components/Home/Services'
import ExhibitorList from '../components/Home/ExhibitorList'
import HowtoExhibit from '../components/ExhibitorGuide/HowtoExhibit'
import { client } from '../client'

const ExhibitorGuide = () => {
  const [homeContent, setHomeContent] = useState({})
  const handleHomeContent = () =>{
    const query = '*[_type == "guide"]'
    
    client.fetch(query).then((data) => {
      console.log(data)
      setHomeContent(data[0])
     });
  }
  useEffect(() => {
   handleHomeContent()
  }, [])
  console.log(homeContent)
  return (
    <div>
        <NavbarWithMegaMenu />
        <GuideBanner />
        <HowtoExhibit />
        <ExhibitorManual homeContent={homeContent} />
        <Services />
        <ExhibitorList />
        <Footer />
    </div>
  )
}

export default ExhibitorGuide