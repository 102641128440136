import React from 'react'
import {Slide, Zoom} from "react-awesome-reveal"
import AboutImageOne from "../../asset/aboutUs1.jpg"
import { Link } from 'react-router-dom'
const Mission = () => {
  return (
    <div className='grid  items-center px-4 lg:px-12 py-3 lg:py-12 grid-cols-1 lg:grid-cols-5 grid-flow-row gap-4 lg:gap-12' >
    <div className='lg:col-span-3' >
    <Slide>
        <h5 className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Our Mission</h5>
        <h3 className='font-[LeagueBold] my-3 leading-[1.8rem] lg:leading-[3rem] text-[1.8rem] lg:text-[2.5rem]' > Shaping the Future of Lifestyle <br />  Exhibitions</h3>
        <p className='font-[LeagueRegular] text-[0.8rem] lg:text-[1.2rem] text-gray-600' > At Ekttaar, our mission is to enrich lives through inspiring and elevating lifestyle experiences. We are dedicated to cultivating connections and celebrating creativity by curating exclusive exhibitions that showcase the best in fashion, beauty, art, and design.<br/><br/>
        Our goal is to elevate everyday moments into unforgettable experiences, shaping the future of lifestyle exhibitions with innovation and passion. We strive to inspire discovery and foster a community where individuals can explore, connect, and indulge in luxury, ultimately transforming the way people experience and appreciate lifestyle. Join us on our journey as we empower elegance and redefine what it means to live a truly enriched life.
        </p>
        <Link to="/visitor-registration" >
        <button className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Visitor</button>
        </Link>
        </Slide>
    </div>
    <div className='lg:col-span-2' >
    <Zoom>
        <img className='rounded-lg shadow-lg ' src={AboutImageOne} />
        </Zoom>
    </div>
</div>
  )
}

export default Mission