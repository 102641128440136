import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
  } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import BannerOne from "../../asset/banner1.png"
import BannerMobile from "../../asset/bannerMobile.png"
import { urlFor } from '../../client';
import { Link } from 'react-router-dom';
const Banner = ({homeContent}) => {
  return (
    <>
    <div className='lg:mx-12 min-h-[600px] bg-gray-200 hidden md:block mx-4' >
       {homeContent?.bannerPc &&  <Swiper
      spaceBetween={50}
      slidesPerView={1}
      
    >
  {homeContent?.bannerPc?.map((image)=>(
    <SwiperSlide>
    <Link to="/visitor-registration">
        <img className='rounded-lg w-full ' src={urlFor(image)} />
        </Link>
      </SwiperSlide>
  ))    }
     
    </Swiper>}
    </div>
    <div className='lg:mx-12 min-h-[380px] mb-4 bg-gray-200 md:hidden block mx-4' >
    {homeContent?.bannerMobile &&   <Swiper
      spaceBetween={50}
      slidesPerView={1}
    
    >
      {homeContent?.bannerMobile?.map((image)=>(  <SwiperSlide>
        <Link to="/visitor-registration">
        <img className='rounded-lg w-full' src={urlFor(image)} />
        </Link>
      </SwiperSlide>))}
     
    </Swiper> }
    </div>
    </>
  )
}

export default Banner