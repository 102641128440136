import React from 'react'

const Quote = () => {
  return (
    <div className='py-3 px-4 lg:px-48 lg:py-8 ' >
        <div className='text-center' >
            <h3 className='font-[RocaSemibold] text-[1.8rem]  italic' >" My life has been shaped by a deep-seated desire to forge meaningful connections and foster
unmatched growth in those around me!"</h3>
    <p className='font-[LeagueBold] my-3' >- by Seema Garg</p>
        </div>
    </div>
  )
}

export default Quote