import React from 'react'
import { InstagramEmbed } from 'react-social-media-embed';
const VideoGallery = () => {
  return (
    <div className='lg:px-12 px-4 py-3 lg:py-12' >
         <div className='lg:flex items-center justify-between' >
    <div >
         <p className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Checkout Our Instagram </p>
        <h3 className='font-[LeagueBold] text-center lg:text-left  my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Our Video Gallery</h3>
        </div>
        
        </div>
        <div className='grid py-3 lg:py-8 lg:grid-cols-4 grid-flow-row gap-6 ' >
        <InstagramEmbed url="https://www.instagram.com/p/C5QI0AKyiWs/"  />
        <InstagramEmbed url="https://www.instagram.com/p/CvjLzEBIViD/"  />
        <InstagramEmbed url="https://www.instagram.com/p/CqaWHtIDwaN/"  />
        <InstagramEmbed url="https://www.instagram.com/p/CqzQE5uLRqW/"  />
        <InstagramEmbed url="https://www.instagram.com/p/Cq2NvAyt2Dr/"  />
        <InstagramEmbed url="https://www.instagram.com/p/Cq2bXXSvNcJ/"  />
        <InstagramEmbed url="https://www.instagram.com/p/CvcU5Emodgc/"  />
        <InstagramEmbed url="https://www.instagram.com/p/C4uxSPuSyz8/"  />
        <InstagramEmbed url="https://www.instagram.com/p/C3epUGGS8M5/"  />
        </div>
    </div>
  )
}

export default VideoGallery