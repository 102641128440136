import React from 'react'
import VideoOne from "../../asset/video1.mp4"
import { Slide, Zoom } from 'react-awesome-reveal'
const WhyEkttaar = () => {
  return (
    <div className='px-4 lg:px-12 py-3 lg:py-4' >
        <div>
        <Slide >    <h3 className='font-[LeagueBold] text-[2.5rem] my-3' >Why Ekttaar</h3>
            <p className='font-[LeagueRegular] text-[0.8rem] lg:text-[1.2rem] text-gray-600' >
Welcome to Ekttaar, your premier destination for captivating lifestyle exhibitions. With a passion for curating extraordinary experiences, we bring together the finest in fashion, beauty, art, and design. Discover exclusive collections from renowned brands and emerging talents. Immerse yourself in a world of style and sophistication, where every detail is thoughtfully curated to inspire and delight. Join us for unforgettable moments of discovery and indulgence, where luxury meets lifestyle. Experience Ekttaar and elevate your senses.</p></Slide>
        </div>
        <div className='my-3 lg:my-6 ' >
          <Zoom>  <video className='w-full rounded-lg' autoPlay muted loop >
                <source src={VideoOne} type="video/mp4" />
            </video></Zoom>
        </div>
    </div>
  )
}

export default WhyEkttaar