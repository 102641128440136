import { EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Slide } from 'react-awesome-reveal'

const ContactDetails = () => {
  return (
    <div className='col-span-1' >
     <Slide triggerOnce>   <div className='mt-4 border-b-[1px] border-gray-200 py-3' >
            <h3 className='font-[LeagueMedium] flex items-center justify-start gap-3 text-[1.2rem] ' ><EnvelopeIcon className='w-[30px] text-[#D46D1B]' /> Email:  </h3>
            <a href='mailTo:info@ekttaar.com' > <p className='font-[LeagueSemibold] text-[1.1rem] lg:text-[1.5rem]' >info@ekttaar.com</p></a>
            <a href='mailTo:support@ekttaar.com' > <p className='font-[LeagueSemibold] text-[1.1rem] lg:text-[1.5rem]' >support@ekttaar.com</p></a>
        </div></Slide>
        <Slide triggerOnce>   <div className='mt-4 border-b-[1px] border-gray-200 py-3' >
            <h3 className='font-[LeagueSemibold] flex items-center justify-start gap-3 text-[1.2rem] ' ><PhoneIcon className='w-[30px] text-[#D46D1B]'  /> Contact No:  </h3>
            <a href='tel:+91 9625606022' > <p className='font-[LeagueSemibold] text-[1.1rem] lg:text-[1.5rem]' >
           +91 9625606022
            </p></a> 
            <a href='tel:+91 9811545835' > <p className='font-[LeagueSemibold] text-[1.1rem] lg:text-[1.5rem]' >
            +91 9811545835
            </p></a>
           
            
        </div></Slide>
        <Slide triggerOnce> <div className='mt-4 border-b-[1px] border-gray-200 py-3' >
            <h3 className='font-[LeagueSemibold] flex items-center justify-start gap-3 text-[1.2rem]' ><MapPinIcon className='w-[30px] text-[#D46D1B]' /> Address One:  </h3>
            <p className='font-[LeagueSemibold] text-[0.7rem] lg:text-[0.9rem]' >2 -garg house,
Raquet court road,
 Civil lines ,
Delhi-54</p>
            
        </div></Slide>
        <Slide triggerOnce> <div className='mt-4 border-b-[1px] border-gray-200 py-3' >
            <h3 className='font-[LeagueSemibold] flex items-center justify-start gap-3 text-[1.2rem]' ><MapPinIcon className='w-[30px] text-[#D46D1B]' /> Address Two:  </h3>
            <p className='font-[LeagueSemibold] text-[0.7rem] lg:text-[.9rem]' >FALCON NESTBUILT PRIVATE LIMITED | PART 1, 7 MILESTONE, BILASPUR TAORU ROAD,
BILASPUR, Mewat, Haryana, 122105</p>
            
        </div></Slide>
    </div>
  )
}

export default ContactDetails