import React, { useEffect, useState } from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import GuideBanner from '../components/VisitorGuide/GuideBanner'
import Footer from '../components/Layout/Footer'
import VisitorManual from '../components/VisitorGuide/VisitorManual'
import HowtoVisit from '../components/VisitorGuide/HowtoVisit'
import Glimpse from '../components/Home/Glimpse'
import { client } from '../client'

const VisitorGuide = () => {
  const [homeContent, setHomeContent] = useState({})
  const handleHomeContent = () =>{
    const query = '*[_type == "guide"]'
    
    client.fetch(query).then((data) => {
      console.log(data)
      setHomeContent(data[0])
     });
  }
  useEffect(() => {
   handleHomeContent()
  }, [])
  console.log(homeContent)
  return (
    <div>
      <NavbarWithMegaMenu />
      <GuideBanner />
      <HowtoVisit />
      <VisitorManual homeContent={homeContent} />
      <Glimpse />
      <Footer />
    </div>
  )
}

export default VisitorGuide