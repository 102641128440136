import React from 'react'
import ApparelImage from "../../asset/apparel.png"
import BakingImage from "../../asset/baking.png"
import JewelleryImage from "../../asset/jewellery.png"
import PotteryImage from "../../asset/pottery.png"
import GiftingImage from "../../asset/gifting.png"
import AscessoriesImage from "../../asset/ascessories.png"
import { Zoom } from 'react-awesome-reveal'
const Services = () => {
  return (
    <div className='bg-gray-100 px-4 lg:px-12 py-3 lg:py-8 my-3' >
         <p className='lg:text-[1.1rem] text-[0.8rem] text-center font-[RocaBold] text-[#FFC108]' >Curating Elegance, Crafting Memories.</p>
        <h3 className='font-[LeagueBold] text-center my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Exibiting Product and Services</h3>
        <div className='grid grid-cols-1 lg:grid-cols-3 mt-8 lg:mt-16 gap-8 lg:gap-20 grid-flow-row item-center justify-center ' >
        <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={ApparelImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Apparel</h5>
                    <p className='font-[LeagueMedium] text-center' >Discover curated fashion for every style and occasion.</p>
                </div>
                </Zoom>
                <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={BakingImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Baked Foods</h5>
                    <p className='font-[LeagueMedium] text-center' >Indulge in artisanal delights, crafted with love and passion.</p>
                </div>
                </Zoom>
                <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={JewelleryImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Jewellery</h5>
                    <p className='font-[LeagueMedium] text-center' >Adorn yourself with timeless elegance and exquisite craftsmanship.</p>
                </div>
                </Zoom>
                <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={PotteryImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Pottery</h5>
                    <p className='font-[LeagueMedium] text-center' >Experience the beauty of handcrafted ceramics, each piece a work of art.</p>
                </div>
                </Zoom>
                <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={GiftingImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Gifting</h5>
                    <p className='font-[LeagueMedium] text-center' >Find the perfect token of appreciation for every special moment.</p>
                </div>
                </Zoom>
                <Zoom>
                <div>
                    <img className='rounded-lg shadow-lg' src={AscessoriesImage} />
                    <h5 className='font-[LeagueBold] text-[1.5rem] text-center my-2' >Accessories</h5>
                    <p className='font-[LeagueMedium] text-center' >Complete your look with chic accents, adding flair to every ensemble.</p>
                </div>
                </Zoom>
        </div>  
    </div>
  )
}

export default Services