import React from 'react'
import { GiArchiveRegister } from "react-icons/gi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { Zoom } from 'react-awesome-reveal';
const HowtoExhibit = () => {
  return (
    <div className='lg:px-12 px-4 py-3 lg:py-12' >
        <h3 className='font-[LeagueBold] text-center lg:text-left text-[1.5rem] lg:text-[2rem]' >How to Become a Exhibitor</h3>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-12 mt-6 grid-flow-row' >
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <GiArchiveRegister className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Register</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Fill the Exhibitor Registration Form and Submit </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <RiCustomerService2Fill className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Call</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Our Team Will Get In Touch With You For Further Process. </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <MdOutlineTableRestaurant className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Stall</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Choose a Stall Size and Type and Finalize the Registration </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <RiMoneyRupeeCircleLine className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Payment</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Pay for the Stall and Get Your Spot</p>
                </Zoom>
            </div>
        </div>
        
    </div>
  )
}

export default HowtoExhibit