
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import VisitorRegistration from './pages/VisitorRegistratio';
import ExhibitorRegistration from './pages/ExhibitorRegistration';
import About from './pages/About';
import ExhibitorGuide from './pages/ExhibitorGuide';
import VisitorGuide from './pages/VisitorGuide';
import Founder from './pages/Founder';
import PastExhibitions from './components/ExhibitorGuide/PastExhibitions';
import ExhibitorList from './pages/ExhibitorList';

function App() {
  return (
    <div >
     <Routes >
     <Route exact path="/" element={<Home/>}   />
     <Route exact path="/gallery" element={<Gallery />}   />
     <Route exact path="/contact" element={<Contact />}   />
     <Route exact path="/visitor-registration" element={<VisitorRegistration />}   />
     <Route exact path="/visitor-guide" element={<VisitorGuide />}   />
     <Route exact path="/exhibitor-registration" element={<ExhibitorRegistration />}   />
     <Route exact path="/exhibitor-guide" element={<ExhibitorGuide />}   />
     <Route exact path="/past-exhibitor" element={<ExhibitorList />}   />
     <Route exact path="/about" element={<About />}   />
     <Route exact path="/founder" element={<Founder />}   />
     </Routes>
    </div>
  );
}

export default App;
