import React from 'react'
import { GiArchiveRegister } from "react-icons/gi";
import { IoMdDownload } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineCompass } from "react-icons/ai";
import { Zoom } from 'react-awesome-reveal';
const HowtoVisit = () => {
  return (
    <div className='lg:px-12 px-4 py-3 lg:py-12' >
        <h3 className='font-[LeagueBold] text-center lg:text-left text-[1.5rem] lg:text-[2rem]' >How to Become a Vistor</h3>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-12 mt-6 grid-flow-row' >
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <GiArchiveRegister className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Register</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Fill the Visitor Registration Form and Submit </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <MdOutlineMail className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Email</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Our Team Will Send You a Email with Visitor Pass </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <IoMdDownload className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Download</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Download the Visitor Pass for Further Details </p>
                </Zoom>
            </div>
            <div className='flex items-center justify-center flex-col' >
            <Zoom>
                <AiOutlineCompass className='text-[#FFC108] hover:bg-[#FFC108] hover:text-white  px-3 py-3 my-1 rounded-full border-[1px] border-[#FFC108] text-[4rem]' />
                <h3 className='font-[LeagueBold] text-[1.5rem]' >Visit</h3>
                <p className='font-[RocaRegular] lg:text-[1rem] text-[.8rem] text-center text-gray-600' >Visit the Exhibition and Show the Pass.</p>
                </Zoom>
            </div>
        </div>
        
    </div>
  )
}

export default HowtoVisit