import React from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import AboutBanner from '../components/About/AboutBanner'
import WhyEkttaar from '../components/About/WhyEkttaar'
import Mission from '../components/About/Mission'
import OurVision from '../components/About/OurVision'
import ExhibitorList from '../components/Home/ExhibitorList'
import Glimpse from '../components/Home/Glimpse'

const About = () => {
  return (
    <div>
        <NavbarWithMegaMenu />
        <AboutBanner />
        <WhyEkttaar />
        <Mission />
        <OurVision />
        <ExhibitorList />
        <Glimpse />
        <Footer/>
    </div>
  )
}

export default About