import React from 'react'
import AtalLogo from "../../asset/atal.png"
import ImbalanceLogo from "../../asset/imbalance.png"
import CocopotLogo from "../../asset/cocopots.png"
import FirstResortLogo from "../../asset/firstresort.png"
import RiyaashLogo from "../../asset/riyaash.png"
import KnotizLogo from "../../asset/knotiz.png"
import SilvedoreLogo from "../../asset/ruchika.jpg"
import ClothHausLogo from "../../asset/clothhaus.png"
import { Zoom } from 'react-awesome-reveal'
const ExhibitorList = () => {
  return (
    <div className='flex items-center flex-col justify-center px-4 lg:px-12 py-3 lg:py-4 '>
    <p className='lg:text-[1.1rem] text-[0.8rem] text-center font-[RocaBold] text-[#FFC108]' >Step into a World of Sophistication and Style.</p>
        <h3 className='font-[LeagueBold] text-center my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Our Past Exhibitors</h3>
        <div className='grid items-center my-3 lg:my-6 justify-center grid-cols-2 lg:grid-cols-4  grid-flow-row' >
            <div className='border-r-[2px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={AtalLogo} />
                </Zoom>
            </div>
            <div className='border-r-[2px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={ImbalanceLogo} />
                </Zoom>
            </div>
            <div className='border-r-[1px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={CocopotLogo} />
                </Zoom>
            </div>
            <div className='border-l-[1px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={FirstResortLogo} />
                </Zoom>
            </div>
            <div className='border-r-[2px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={RiyaashLogo} />
                </Zoom>
            </div>
            <div className='border-r-[2px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={KnotizLogo} />
                </Zoom>
            </div>
            <div className='border-r-[1px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={SilvedoreLogo} />
                </Zoom>
            </div>
            <div className='border-l-[1px] border-b-[2px] border-gray-300' >
            <Zoom>
                <img className='w-[200px] py-4 px-4' src={ClothHausLogo} />
                </Zoom>
            </div>
        </div>
       
    </div>
  )
}

export default ExhibitorList