import React from 'react'
import { Link } from 'react-router-dom'
import ImageOne from "../../asset/exh1.heic"
import ImageTwo from "../../asset/exh2.jpg"
import ImageThree from "../../asset/exh3.jpg"
import ImageFour from "../../asset/exh4.heic"
import ImageFive from "../../asset/exh5.webp"
const PastExhibitions = () => {
  return (
    <div className='lg:px-12 px-4 py-3 lg:py-12' >
    <div className='lg:flex items-center justify-between' >
<div >
    <p className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Style, Inspiration, and Everything In Between</p>
   <h3 className='font-[LeagueBold] text-center lg:text-left  my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Our Past Exhibitions</h3>
   </div>
  
   </div>
   <div className='grid py-3 lg:py-8 lg:grid-cols-3 grid-flow-row gap-6 lg:gap-12 ' >
   <div>
                <img className='rounded-lg  shadow-lg' src={ImageOne} alt="" />
            </div>
            <div>
                <img className='rounded-lg  shadow-lg' src={ImageTwo} alt="" />
            </div>
            <div>
                <img className='rounded-lg shadow-lg' src={ImageThree} alt="" />
            </div>
            <div>
                <img className='rounded-lg  shadow-lg' src={ImageFour} alt="" />
            </div>
            <div>
                <img className='rounded-lg  shadow-lg' src={ImageFive} alt="" />
            </div>
   </div>
</div>
  )
}

export default PastExhibitions