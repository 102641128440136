import React, { useState } from 'react'
import BackgroundImage from "../asset/glimpse/image11.jpg"
import { client } from '../client'

const ExhibitorRegistration = () => {
    const [formData, setFormData] = useState({
        fname:"",
        lname:"",
        email:"",
        phone:"",
        company:"",
        stall:"",
        jobTitle:"",
        nature:""
    })
    const handleSubmit = () =>{
        if(formData?.fname?.length===0){
            alert("Enter First Name")
        }
        else if(formData?.lname?.length===0){
            alert("Enter Last Name")
        }
        else if(formData?.email?.length===0){
            alert("Enter Email")
        }
        else if(formData?.phone?.length===0){
            alert("Enter Contact Number")
        }
        else if(formData?.company?.length===0){
            alert("Enter Company Name")
        }
        else if(formData?.stall?.length===0){
            alert("Enter Stall Size")
        }
        else if(formData?.jobTitle?.length===0){
            alert("Enter Job Title")
        }
        else if(formData?.nature?.length===0){
            alert("Enter Nature of Business")
        }
        else{
            const doc = {
                _type: "exhibitorRegistration",
                fname:formData?.fname,
                lname:formData?.lname,
                email:formData?.email,
                contact:formData?.phone,
                company:formData?.company,
                stall:formData?.stall,
                jobTitle:formData?.jobTitle,
                nature:formData?.nature
             }
            
            client.create(doc).then((response)=>{
              console.log(response)
              setFormData({
                  fname:"",
                  lname:"",
                  email:"",
                  phone:"",
                  company:"",
                  stall:"",
                  jobTitle:"",
                  nature:""
             })
            })
        }
        
    }
  return (
    <div style={{backgroundImage:`url(${BackgroundImage})`}} className='bg-center flex items-center justify-center bg-cover w-[100vw] lg:h-[100vh]' >
    <div className='bg-white px-3 my-3 lg:my-0 mx-2 lg:mx-0 lg:px-8 py-4 rounded-lg' > 
    <p className='font-[RocaBold] text-center text-[0.8rem] lg:text-[1.3rem] text-[#FFC108]' >Exhibitor Registration</p>
        <h3 className='font-[LeagueBold] text-center my-3 lg:leading-[3rem] text-[1.5rem] lg:text-[2.5rem]'>Fill The Form and Register Yourself</h3>
        <div>
        <div className='my-2' >
    <div className='lg:grid grid-cols-2 lg:my-2 grid-flow-col gap-3' >
        <div>
            <label className='font-[LeagueSemibold] my-1' >First Name<span className='text-red-600' >*</span></label>
            <input value={formData?.fname} onChange={(e)=>setFormData({...formData,fname:e.target.value})} className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
        <div>
            <label className='font-[LeagueSemibold] my-1' >Last Name<span className='text-red-600' >*</span></label>
            <input value={formData?.lname} onChange={(e)=>setFormData({...formData,lname:e.target.value})} className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
    </div>
    <div className='lg:grid grid-cols-2 lg:my-2 grid-flow-col gap-3' >
        <div>
            <label className='font-[LeagueSemibold] my-1' >Mobile Number<span className='text-red-600' >*</span></label>
            <input type="number" value={formData?.phone} onChange={(e)=>setFormData({...formData,phone:e.target.value})} className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
        <div>
            <label className='font-[LeagueSemibold] my-1' >Email<span className='text-red-600' >*</span></label>
            <input value={formData?.email} onChange={(e)=>setFormData({...formData,email:e.target.value})} className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
    </div>
    <div className='lg:grid grid-cols-2 lg:my-2 grid-flow-col gap-3' >
        <div>
            <label className='font-[LeagueSemibold] my-1' >Company Name<span className='text-red-600' >*</span></label>
            <input value={formData?.company} onChange={(e)=>setFormData({...formData,company:e.target.value})} className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
        <div>
            <label className='font-[LeagueSemibold] my-1' >Stall Size<span className='text-red-600' >*</span></label>
            <select value={formData?.stall} onChange={(e)=>setFormData({...formData,stall:e.target.value})} className='py-2 px-3 my-2 border-gray-300 w-full border-[1px] outline-none' >
            <option value="" >Select an Option</option>
                <option value="9 sq.m">9 sq.m</option>
                <option value="12 sq.m">12 sq.m</option>
                <option value="15 sq.m">15 sq.m</option>
                <option value="18 sq.m" >18 sq.m</option>
                <option value="24 sq.m" >24 sq.m</option>
                <option value="36 sq.m" >36 sq.m</option>
                <option value=">36 sq.m" >+36 sq.m</option>
            </select>
        </div>
    </div>
    <div className='lg:grid grid-cols-2 lg:my-2 grid-flow-col gap-3' >
    <div>
            <label className='font-[LeagueSemibold] my-1' >Job Title <span className='text-red-600' >*</span></label>
            <input value={formData?.jobTitle} onChange={(e)=>setFormData({...formData,jobTitle:e.target.value})}  className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
        <div>
            <label className='font-[LeagueSemibold] my-1' >Primary Nature of Business<span className='text-red-600' >*</span></label>
            <input value={formData?.nature} onChange={(e)=>setFormData({...formData,nature:e.target.value})}  className='py-2 px-3 my-1 border-gray-300 w-full border-[1px] outline-none' />
        </div>
       
    </div>
    <div className='flex items-center justify-center my-2 ' >
        <button onClick={handleSubmit} className='uppercase flex items-center justify-center gap-3 rounded-lg shadow-md  font-[LeagueSemibold] ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#272727] tracking-wider py-[8px] px-[24px] md:py-[12px] md:px-[24px] text-[#FFFEFC] bg-[#D46D1B] ' >Submit</button>
    </div>
</div>
        </div>
    </div>

</div>
  )
}

export default ExhibitorRegistration