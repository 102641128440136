import React from 'react'
import Elephant from '../../asset/elephant.png'
import Unique from "../../asset/unique.jpg"
import { Zoom } from 'react-awesome-reveal'
const Association = () => {
  return (
    <div className='px-4 lg:px-12 py-3 lg:py-12 '>
        <div>
            <h3 className='font-[LeagueBold] capitalize text-center text-[1.5rem] lg:text-[2rem]' >We are Assoicated With</h3>
        </div>
       <div className='flex my-4 items-center flex-wrap justify-center gap-12' >
      <Zoom>  <img className='lg:w-[200px] w-[100px] ' src={Elephant} /></Zoom>
      <Zoom>   <img className='lg:w-[130px] w-[70px] rounded-full' src={Unique } /></Zoom>
       </div>
    </div>
  )
}

export default Association