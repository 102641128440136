import React from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import PastExhibitions from '../components/ExhibitorGuide/PastExhibitions'

const ExhibitorList = () => {
  return (
    <div>
        <NavbarWithMegaMenu />
        <PastExhibitions />
        <Footer />
    </div>
  )
}

export default ExhibitorList