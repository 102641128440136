import React, { useEffect,use, useState } from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import AboutEkttaar from '../components/Home/AboutEkttaar'
import ExhibitorList from '../components/Home/ExhibitorList'
import Glimpse from '../components/Home/Glimpse'
import Banner from '../components/Home/Banner'
import Footer from '../components/Layout/Footer'
import Services from '../components/Home/Services'
import Testimonial from '../components/Home/Testimonial'
import Association from '../components/About/Association'
import { client } from '../client'


const Home = () => {
  const [homeContent, setHomeContent] = useState({})
  const handleHomeContent = () =>{
    const query = '*[_type == "homeContent"]'
    
    client.fetch(query).then((data) => {
      console.log(data)
      setHomeContent(data[0])
     });
  }
  useEffect(() => {
   handleHomeContent()
  }, [])
  console.log(homeContent)
  
  return (
    <div>
        <NavbarWithMegaMenu />
        <Banner homeContent={homeContent} />
        <AboutEkttaar homeContent={homeContent}  />
        <Services />
        <Glimpse homeContent={homeContent}  />
        <ExhibitorList homeContent={homeContent}  />
        <Association homeContent={homeContent}  />
        {/* <Testimonial /> */}
        <Footer />
    </div>
  )
}

export default Home