import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'zczcx6d7',
  dataset: 'ekttaar',
  apiVersion: '2022-03-07',
  useCdn: false,
  token: "sk7xzD0jVvEZ3nvyzWyUCYnkk8ievkvBkTWuFe7Nzf6oZANReRtf3HkcriD7mImFICKgokAWmfSs8muQ33l95LP5q5tX7ZkmjKjThDr4fmA4OAIlmovyeGEtkLW0L6jYWWlG1TooijzsilsHhM0cvtlpHC6hCGIXuqRPkVrDkSzxSd6wIwjx"
});


const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);