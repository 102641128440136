import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import ImageOne from "../../asset/glimpse/image1.jpg"
import ImageTwo from "../../asset/glimpse/image2.jpg"
import ImageThree from "../../asset/glimpse/image3.jpg"
import ImageFour from "../../asset/glimpse/image4.jpg"
import ImageFive from "../../asset/glimpse/image5.jpg"

import ImageSeven from "../../asset/glimpse/image7.jpg"
import ImageEight from "../../asset/glimpse/image8.jpg"
import ImageNine from "../../asset/glimpse/image9.jpg"
import ImageTen from "../../asset/glimpse/image10.jpg"
import { Slide } from 'react-awesome-reveal';
const Glimpse = () => {
  return (
    <div className='px-4 lg:px-12 py-3 lg:py-4 ' >
    <div className='lg:flex items-center justify-between' >
    <div >
    <Slide >
         <p className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >Style, Inspiration, and Everything In Between</p>
        <h3 className='font-[LeagueBold] text-center lg:text-left  my-3 leading-[1.5rem] lg:leading-[3rem] capitalize text-[1.5rem] lg:text-[3rem]'>Glimpse of Our Exhibitions</h3>
        </Slide>
        </div>
        <div>
        <button className='bg-[#D46D1B] lg:block hidden text-white font-[LeagueSemibold] lg:py-3 px-8 rounded-lg mt-4 text-[0.7rem] lg:text-[1.1rem] shadow-lg' >Register as Visitor</button>
        </div>
        </div>
        <div className='lg:mt-4' >
        <Swiper
          spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
        576: {
          width: 576,
          slidesPerView: 2,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
       
         1440:{
          width:1440,
          slidesPerView:3.7
        }
      }}
          autoplay={{
            delay: 1800,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
        
        >
           
       
          <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px]  h-[300px] w-[100%] rounded-xl"
              src={ImageOne}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageTwo}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageThree}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageFour}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageFive}
              alt=""
            />
            
          </div>
            </SwiperSlide>
          
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageSeven}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageEight}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageNine}
              alt=""
            />
            
          </div>
            </SwiperSlide>
            <SwiperSlide>
          <div className="mx-2 my-3 lg:my-12">
            <img
              className="xl:w-[700px] h-[300px] w-[100%] rounded-xl"
              src={ImageTen}
              alt=""
            />
            
          </div>
            </SwiperSlide>
        </Swiper>
        </div>
    </div>
  )
}

export default Glimpse