import React from 'react'
import AboutHome from "../../asset/glimpse/image15.jpg"
import {Slide, Zoom} from "react-awesome-reveal"
import { Link } from 'react-router-dom'
import PortableText from 'react-portable-text'
import { urlFor } from '../../client'
const ExhibitorManual = ({homeContent}) => {
  return (
    <div className='grid px-4 py-3 lg:px-12 items-center lg:py-12 grid-cols-1 lg:grid-cols-5 grid-flow-row gap-12' >
    <div className='col-span-3' >
    <Slide>
        <h5 className='font-[RocaBold] text-[1.3rem] text-[#FFC108]' >{homeContent?.exhibitorManual?.subTitle}</h5>
        <h3 className='font-[LeagueBold] my-3 leading-[3rem] text-[2.5rem]' > {homeContent?.exhibitorManual?.title}</h3>
        <p className='font-[LeagueRegular] text-[0.8rem] lg:text-[1.2rem] text-gray-600' > {homeContent?.exhibitorManual?.content &&   <PortableText
      // Pass in block content straight from Sanity.io
      content={homeContent?.exhibitorManual?.content}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => <h1 style={{ color: "red" }} {...props} />,
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        
      }}
    />}
        </p>
        <Link to="/exhibitor-registration" >
        <button className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Exhibitor</button>
        </Link>
        </Slide>
    </div>
    <div className='col-span-2' >
    <Zoom>
     {homeContent?.exhibitorManual?.imageSection &&   <img className='rounded-lg shadow-lg ' src={urlFor(homeContent?.exhibitorManual?.imageSection)} />}
        </Zoom>
    </div>
</div>
  )
}

export default ExhibitorManual