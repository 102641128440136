import React from 'react'
import Logo from "../../asset/logo.png"
import { IoMdArrowRoundUp } from 'react-icons/io'
import { PiLinkedinLogo } from "react-icons/pi";
import { PiInstagramLogo } from "react-icons/pi";
import { PiYoutubeLogo } from "react-icons/pi";
import {Link} from "react-router-dom"
const Footer = () => {
  return (
    <div className='md:px-12 mx-4 lg:mx-12 my-3 rounded-lg px-4 py-12 shadow-lg bg-[#ebe3d654]' >
    <div className='md:grid grid-col-7 grid-flow-col' >
        <div className='col-span-1' >
            <img className='w-[130px]' src={Logo} />
            <p className='font-[RocaRegular] text-[1rem] text-[#272727]' >Your Lifestyle. Our Exhibit</p>
            <Link to="/visitor-registration" > <button className="uppercase flex items-center justify-center gap-3 rounded-lg shadow-md  font-[LeagueBold] ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#272727] tracking-wider py-[8px] px-[24px] mt-3 md:py-[12px] md:px-[24px] text-[#FFFEFC] bg-[#D64C27]" >Visitor Registration </button></Link>
        </div>
        <div className='col-span-5 flex lg:items-start justify-around pb-4 lg:flex-row flex-col gap-6    mt-3 md:mt-4' >
                <div>
                    <h3 className='font-[LeagueBold] text-[1.5rem] text-[#272727]'>Sitemap</h3>
                    <div className='font-[RocaRegular]' >
                        <Link to="/"><p> Home</p></Link>
                        <Link to="/about"><p> About</p></Link>
                        <Link to="/contact"><p> Contact</p></Link>
                        <Link to="/gallery"><p> Gallery</p></Link>
                        
                    </div>
                </div>
                <div>
                    <h3 className='font-[LeagueBold] text-[1.5rem] text-[#272727]'>Registration</h3>
                    <div className='font-[RocaRegular]'>
                        <Link to="/exhibitor-registration"><p> Register as Exhibitor</p></Link>
                        <Link to="/visitor-registration"><p> Register as Vistor</p></Link>
                        
                    </div>
                </div>
                <div>
                    <h3 className='font-[LeagueBold] text-[1.5rem] text-[#272727]'>Important Links</h3>
                    <div className='font-[RocaRegular]'>
                        <Link to="/visitor-guide"><p> Visitor Guide</p></Link>
                        <Link to="/exhibitor-guide"><p> Exhibitor Guide</p></Link>
                        
                    </div>
                </div>
        </div>
        <div className='col-span-1 mt-3 md:mt-4' >
            <h3 className='font-[LeagueSemiBold]'>support@ekttaar.com</h3>
           <h3 className='font-[LeagueSemiBold]'>+91 9625606022</h3>
           <h3 className='font-[LeagueSemiBold]'>+91 9811545835</h3>
        </div>
    </div>
    <div className='md:grid grid-cols-7 mt-6  grid-flow-col'>
        <div className='col-span-1' ></div>
        <div className='col-span-5 flex items-center justify-center' >
            <h3 className='font-[LeagueRegular]' >2024 FALCON NESTBUILT PRIVATE LIMITED. All right reserved</h3>
        </div>
        <div className='col-span-1 flex items-center md:ml-[-25px] justify-center md:justify-start gap-3' >
           <a target='_blank' rel='noreferrer' href='https://www.instagram.com/ekttaar_byseema/'> <PiInstagramLogo className='text-[25px]' /></a>
            
            <a target='_blank' rel='noreferrer' href='https://www.youtube.com/@unioncoliving'>  <PiYoutubeLogo className='text-[25px]' /> </a>
        </div>
    </div>
</div>
  )
}

export default Footer