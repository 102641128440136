import React from 'react'
import AboutHome from "../../asset/aboutHome.jpg"
import {Fade, Zoom, Slide} from "react-awesome-reveal"
import { urlFor } from '../../client'
import PortableText from 'react-portable-text'
const AboutEkttaar = ({homeContent}) => {
  return (
    <div className='grid  items-center px-4 lg:px-12 py-3 lg:py-4 grid-cols-1 lg:grid-cols-5 grid-flow-row gap-12' >
        <div className='lg:col-span-3 col-span-1' >
        <Slide >
            <h5 className='font-[RocaBold] text-[0.9rem] lg:text-[1.3rem] text-[#FFC108]' >{homeContent?.aboutSummary?.subTitle}</h5>
            <h3 className='font-[LeagueBold] my-3 leading-[1.5rem] lg:leading-[3rem] text-[1.5rem] lg:text-[2.5rem]' >{homeContent?.aboutSummary?.title}  </h3>
            <p className='font-[LeagueRegular] text-[.8rem] lg:text-[1.2rem] text-gray-600' >
         {homeContent?.aboutSummary?.content &&   <PortableText
      // Pass in block content straight from Sanity.io
      content={homeContent?.aboutSummary?.content}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => <h1 style={{ color: "red" }} {...props} />,
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        
      }}
    />}
            </p>
            <button className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Visitor</button>
            </Slide>
        </div>
        <div className='lg:col-span-2 col-span-1' >
        <Zoom>
          {homeContent?.aboutSummary?.imageSection &&  <img className='rounded-lg shadow-lg ' src={urlFor(homeContent?.aboutSummary?.imageSection)} />}
            </Zoom>
        </div>
    </div>
  )
}

export default AboutEkttaar