import React from 'react'
import { Slide, Zoom } from 'react-awesome-reveal'
import AboutImageOne from "../../asset/founder.jpeg"
import { Link } from 'react-router-dom'
const AboutFounder = () => {
  return (
    <div className='grid  items-center px-4 lg:px-12 py-3 lg:py-12 grid-cols-1 lg:grid-cols-5 lg:grid-flow-row grid-flow-reverse-row gap-4 lg:gap-12' >
    <div className='lg:col-span-2 order-last lg:order-first' >
    <Zoom>
        <img className='rounded-lg shadow-lg ' src={AboutImageOne} />
        </Zoom>
    </div>
    <div className='lg:col-span-3' >
    <Slide direction='right' >
        <h5 className='lg:text-[1.1rem] text-[0.8rem] text-center lg:text-left font-[RocaBold] text-[#FFC108]' >About Our Founder</h5>
        <h3 className='font-[LeagueBold] my-3 leading-[3rem] text-[2.5rem]' > One Brand At A Time.</h3>
        <p className='font-[LeagueRegular] text-[0.8rem] lg:text-[1.2rem] text-gray-600' > I’d say, right from the outset,
I have possessed an innate passion for building connections and nurturing relationships at its
best. Whether facilitating partnerships or nurturing budding entrepreneurs, the joy of making a
difference has always been at the core of my being. It was this drive that sparked the birth of
Ekttaar - a venture where these aspirations could seamlessly flourish and thrive. <br /> <br />
Honestly, Ekttaar is not just a venture for me; it is a manifestation of my unwavering belief in the
transformative power of community. Through meticulously curated exhibitions, we aim to
celebrate the rich tapestry of lifestyle brands and businesses while providing a stage for their
voices to be heard. Furthermore, over the past two years, we have successfully organized
exhibitions, and this year, we are gearing up for another remarkable showcase! <br /> <br />
Our ultimate mission at Ekttaar is to empower homegrown brands across a myriad of industries,
from education to culinary arts and beyond assisting them in reaching new heights and carving
out a distinct niche for themselves in the market. We believe in nurturing talent and providing a
supportive environment where entrepreneurs can thrive and grow. We are beyond committed to
championing women entrepreneurs, providing them with the resources and guidance they need
to succeed.<br /> <br />
As we chart the course ahead, I am filled with a sense of anticipation and excitement for Ekttaar
- a testament to the power of dreams and the resilience of the human spirit.
Together, we will continue to forge ahead, building bridges, and creating opportunities for all.
We will continue to uplift and empower aspiring entrepreneurs, making a positive impact on
society - One Brand At A Time.
        </p>
        <Link to="/visitor-registration" >
        <button className='bg-[#D46D1B] text-white font-[LeagueSemibold] py-3 px-8 rounded-lg mt-4 text-[1.1rem] shadow-lg' >Register as Visitor</button>
        </Link>
        </Slide>
    </div>
    
</div>
  )
}

export default AboutFounder