import React, { useState } from 'react'
import { NavbarWithMegaMenu } from '../components/Layout/Navbar'
import ContactBanner from '../components/Contact/ContactBanner'
import ContactForm from '../components/Contact/ContactForm'
import ContactDetails from '../components/Contact/ContactDetails'
import Footer from "../components/Layout/Footer"
import { client } from '../client'
const Contact = () => {
  const [formData, setFormData] = useState({
   name:"",
   email:"",
   contact:"",
   subject:"",
   message:""
})
const handleSubmit = () =>{
    if(formData?.name?.length===0){
        alert("Enter Name")
    }
    else if(formData?.email?.length===0){
        alert("Enter Email")
    }
    else if(formData?.contact?.length===0){
        alert("Enter Contact No")
    }
    else if(formData?.subject?.length===0){
        alert("Enter Subject")
    }
    else if(formData?.message?.length===0){
        alert("Enter Message")
    }
    else{
        const doc = {
            _type: "enquiry",
            name:formData.name,
            email:formData.email,
            contact:formData.contact,
            subject:formData.subject,
            message:formData.message
         }
        
        client.create(doc).then((response)=>{
          console.log(response)
          setFormData({
            name:"",
            email:"",
            contact:"",
            subject:"",
            message:""
         })
        })
    }
     
}
  return (
    <div>
        <NavbarWithMegaMenu />
        <ContactBanner />
        <div className='grid px-4 py-3 lg:py-12 lg:px-12 items-center gap-3 lg:gap-12 lg:grid-cols-2 grid-flow-row' >
            <ContactForm handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} />
            <ContactDetails  />
        </div>
        <Footer />
    </div>
  )
}

export default Contact