import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import {
  Bars4Icon,
  GlobeAmericasIcon,
  NewspaperIcon,
  PhoneIcon,
  RectangleGroupIcon,
  SquaresPlusIcon,
  SunIcon,
  TagIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { LiaWpforms } from "react-icons/lia";
import { TbTruckDelivery } from "react-icons/tb";
import { FiTruck } from "react-icons/fi";
import { PiWarehouseDuotone } from "react-icons/pi";
import { PiShoppingCartSimpleLight } from "react-icons/pi";
import { PiAirplaneInFlight } from "react-icons/pi";
import { PiTrain } from "react-icons/pi";
import { LiaShippingFastSolid } from "react-icons/lia";
import { IoBusinessOutline } from "react-icons/io5";
import { PiPersonArmsSpreadDuotone } from "react-icons/pi";
import { GrCircleInformation } from "react-icons/gr";
import { PiPhoneCallLight } from "react-icons/pi";
import { PiBriefcaseMetalDuotone } from "react-icons/pi";
 import LogoMain from "../../asset/logo.png"
import { Link } from "react-router-dom";
const navListMenuItems = [
  {
    title: "Registration",
    description: "Find the perfect solution for your needs.",
    icon: LiaWpforms,
    link:"/exhibitor-registration"
  },
  {
    title: "Exhibitor Guide",
    description: "Find the perfect solution for your needs.",
    icon: FiTruck,
    link:"/exhibitor-guide"
  },
  {
    title: "Past Exhibitions",
    description: "Find the perfect solution for your needs.",
    icon: LiaWpforms,
    link:"/past-exhibitor"
  },
 
];
const solutionNavListMenuItems = [
  {
    title: "Registration",
    description: "Find the perfect solution for your needs.",
    icon: LiaWpforms,
    link:"/visitor-registration"
  },
  {
    title: "Visitor Guide",
    description: "Find the perfect solution for your needs.",
    icon: PiShoppingCartSimpleLight,
    link:"/visitor-guide"
  },
 
];
const aboutNavListMenuItems = [
  {
    title: "Our Story",
    description: "Find the perfect solution for your needs.",
    icon: GrCircleInformation,
    link:"/our-story"
  },
  {
    title: "Contact",
    description: "Find the perfect solution for your needs.",
    icon: PiPhoneCallLight,
    link:"/contact"
  },
 
  
];
 
function NavListMenu({menuList,title}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = menuList.map(
    ({ icon, title, description,link }, key) => (
      <Link to={link} key={key}>
        <MenuItem className="flex items-center justify-start  rounded-lg">
         
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex font-[LeagueSemibold] items-center text-sm font-bold"
            >
              {title}
            </Typography>
            
          </div>
        </MenuItem>
      </Link>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <p >
            <ListItem
              className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center  gap-2 lg:py-2 py-0 pr-4"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {title}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </p>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-1 gap-y-0 lg:gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
function NavList() {
  return (
    <List className="lg:mt-4 lg:mb-6 gap-2 p-0 mt-0 mb-0 lg:flex-row lg:p-1">
    <Link to="/" ><ListItem className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center gap-2 lg:py-2 py-0 pr-4">Home</ListItem></Link> 
    <Link to="/about" ><ListItem className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center gap-2 lg:py-2 py-0 pr-4">About</ListItem></Link> 
    <Link to="/founder" ><ListItem className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center gap-2 lg:py-2 py-0 pr-4">Founder</ListItem></Link> 
      <Link to="/contact" > <ListItem className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center gap-2 lg:py-2 py-0 pr-4">Contact</ListItem></Link>
   <Link to="/gallery" >  <ListItem className="flex text-[#272727]  hover:text-gray-700 hover:bg-transparent font-[LeagueSemibold] text-[1rem] items-center gap-2 lg:py-2 py-0 pr-4">Gallery</ListItem></Link>
   <NavListMenu title="Visitor" menuList={solutionNavListMenuItems}  />
   <NavListMenu title="Exhibitor" menuList={navListMenuItems}  />
      

      
    
    </List>
  );
}
 
export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
    <Navbar className="mx-[0px] bg-white max-w-full shadow-none rounded-none px-4 lg:px-12 py-3 lg:py-4">
      <div className="flex items-center justify-between text-blue-gray-900">
      <Link to="/" >
        <img
        className="w-[100px]"
         src={LogoMain}
        />
     
     </Link>
        <div className="hidden lg:block">
          <NavList />
      
        </div>
        <div className="hidden gap-2 lg:flex">
        <Link to="/visitor-registration" >
        <button className='bg-[#D46D1B] lg:block hidden text-white font-[LeagueSemibold] lg:py-3 px-8 rounded-lg mt-4 text-[0.7rem] lg:text-[1.1rem] shadow-lg' >Register as Visitor</button>
        </Link>
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList  />
        
      </Collapse>
    </Navbar>
  );
}