import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter,HashRouter } from 'react-router-dom';
import ScrollToTop from './components/Layout/ScrollToTop';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
  <HashRouter basename='/'>
  <ScrollToTop />
    <App />
    </HashRouter>
  </ThemeProvider>
);

